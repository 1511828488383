export const initialState = {
  sgpaData: null,
  cgpaData: null,
  result: null,
  branch: null,
};

export const actionTypes = {
  SET_SGPADATA: "SET_SGPADATA",
  SET_CGPADATA: "SET_CGPADATA",
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.SET_SGPADATA:
      return {
        ...state,
        sgpaData: action.data,
        result: action.result,
        branch: action.branch,
      };
    case actionTypes.SET_CGPADATA:
      return {
        ...state,
        cgpaData: action.data,
        result: action.result,
        branch: action.branch,
      };
    default:
      return state;
  }
};

export default reducer;
