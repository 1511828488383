import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";

const Home = lazy(() => import("./pages/Home"));
const Sem = lazy(() => import("./pages/Sem"));
const FirstSemPhy = lazy(() => import("./pages/sems/FirstSemPhy"));
const FirstSemChem = lazy(() => import("./pages/sems/FirstSemchem"));
const ThirdSem = lazy(() => import("./pages/sems/ThirdSem"));
const FourthSem = lazy(() => import("./pages/sems/FourthSem"));
const FifthSem = lazy(() => import("./pages/sems/FifthSem"));
const SixthSem = lazy(() => import("./pages/sems/SixthSem"));
const Branch7 = lazy(() => import("./pages/sems/7thSem/Branch7"));
const CSE = lazy(() => import("./pages/sems/7thSem/CSE"));
const EC = lazy(() => import("./pages/sems/7thSem/EC"));
const EighthSem = lazy(() => import("./pages/sems/EighthSem"));
const CGPACalc = lazy(() => import("./pages/CGPACalc"));
const Download = lazy(() => import("./pages/Download"));
const CGPADownload = lazy(() => import("./pages/CGPADownload"))
const Error = lazy(() => import("./pages/Error"));

function App() {
  return (
    <Router>
      <div className="App">
        <Suspense
          fallback={
            <div className="fallback">
              <h1 className="fallback-heading">Loading...</h1>
            </div>
          }
        >
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/sem" component={Sem} />
            <Route path="/fistsemphy" component={FirstSemPhy} />
            <Route path="/fistsemchem" component={FirstSemChem} />
            <Route path="/thirdsem" component={ThirdSem} />
            <Route path="/fourthsem" component={FourthSem} />
            <Route path="/fifthsem" component={FifthSem} />
            <Route path="/sixthsem" component={SixthSem} />
            <Route path="/branch7" component={Branch7} />
            <Route path="/cse7" component={CSE} />
            <Route path="/ec7" component={EC} />
            <Route path="/eightsem" component={EighthSem} />
            <Route path="/cgpacalc" component={CGPACalc} />
            <Route path="/download" component={Download} />
            <Route path="/cgpa-download" component={CGPADownload} />
            <Route path="*" component={Error}/>
          </Switch>
        </Suspense>
      </div>
    </Router>
  );
}

export default App;
